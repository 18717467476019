// "use client";

import Link from "next/link";

const SITEMAP = [
  {
    title: "Contact Us",
    menu: ["+92(21) 32428196-98", "Suite 208-209, New PSX Building,", "Stock Exhchange Road. Karachi,", "Pakistan.","info@nubitsoft.com"],
  },
  {
    title: "Trending Tech",
    menu: ["Full Stack Development", "Machine Learning & AI", "NFT MarketPlace", "Cyber Security","Augmented Reality"],
  },
  {
    title: "Industries",
    menu: ["Financial Services", "Education", "Healthcare", "Public Sector",  "B P O "],
  },
  {
    title: "Quick Link",
    links: ["about", "sitemap","contact ", "Facebook", "linkedIn"],
  },
];

export function Footer() {
  return (
    <footer className="mb-auto w-full bg-black text-white">
      <div className="px-8 flex w-full flex-col items-center justify-center">
        <div className="grid justify-items-center w-full grid-cols-2 gap-8 py-12 md:grid-cols-3 lg:grid-cols-4">
          {SITEMAP.map(({ title, links,menu }, index) => (
            <div key={index} className="w-full">
              <h3 className="mb-4 font-bold uppercase opacity-50 text-white">{title}</h3>
              <ul className="space-y-1">
              {links?.map((links, index) => (
                  <li key={index}>
                    <Link
                      href={links=="linkedIn"?'https://www.linkedin.com/company/nubit-software-pvt-ltd/posts/?feedView=all': links == "Facebook" ?'https://www.facebook.com/Nubitsoftware':links}
                      className="inline-block py-1 pr-2 transition-transform hover:scale-105 text-white "
                    >
                      {links}
                    </Link>
                  </li>
                ))}
                {menu?.map((menu, index) => (
                  <li key={index}>
                    <span className="inline-block py-1 pr-2 transition-transform hover:scale-105 text-white ">
                      {menu}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <div className="flex w-full flex-col items-center justify-center border-t border-white opacity-50 py-2 sm:py-4 md:flex-row md:justify-between">
          <span className="mb-2 text-center text-sm lg:text-md text-white  md:mb-0">
          © Powered By NubitSoft. All Right Reserved.
          </span>
          <div className="flex gap-4 text-white sm:justify-center items-center">
            <Link href="https://www.linkedin.com/company/nubit-software-pvt-ltd/posts/?feedView=all" className="opacity-80 transition-opacity hover:opacity-100">
              <svg className="h-5 w-5"  fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
              <path d="M22.23 0H1.77C.79 0 0 .77 0 1.74v20.52C0 23.23.79 24 1.77 24h20.46C23.21 24 24 23.23 24 22.26V1.74C24 .77 23.21 0 22.23 0zM7.09 20.45H3.56V9.04h3.53v11.41zM5.33 7.55a2.03 2.03 0 1 1 0-4.05 2.03 2.03 0 0 1 0 4.05zm14.68 12.9h-3.53v-5.53c0-1.32-.03-3.03-1.85-3.03-1.85 0-2.13 1.44-2.13 2.93v5.63H9.98V9.04h3.39v1.56h.05c.47-.88 1.61-1.8 3.31-1.8 3.54 0 4.19 2.33 4.19 5.36v6.29z"/>
              </svg>
            </Link>
            <Link href="https://www.facebook.com/Nubitsoftware" className="opacity-80 transition-opacity hover:opacity-100">
              <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                <path
                  fillRule="evenodd"
                  d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                  clipRule="evenodd"
                />
              </svg>
            </Link>
           
          </div>
        </div>
      </div>
    </footer>
  );
}
