import React from 'react'
import { HoverBorderGradient } from "../ui/hover-border-gradient";
import Link from 'next/link';
const HoverBtn = () => {
  return (
    <div className=" flex justify-center text-center">

      <Link href={'https://wa.link/ppbmjw'}>
    <HoverBorderGradient
      containerClassName="rounded-full"
      as="button"
      className="bg-black text-white flex items-center p-4"
    >
      {/* <AceternityLogo /> */}
      <span>Get In Touch</span>
    </HoverBorderGradient>
      </Link>
    </div>
  )
}

export default HoverBtn