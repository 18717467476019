import ShowPortfolio from './showPortfolio'
export function Portfolio() {

  return (
    (<div
      className="h-full w-full flex-col rounded-md flex items-center justify-center mt-7   bg-grid-white/[0.02] ">
        <ShowPortfolio/>
    </div>)
  );
}
