import { ApplicantCard } from "./ApplicantRequest";
import { Footer } from "./Footer";
import OfficePic  from './OfficePic'
import GlobeDemo from './Globe'
import {HeroTitle} from './HeroTitle'
import {HoverCard} from './HoverCard'
import ServicesCard from './Services'
import HeroSection from './HeroSection'
import {SkeletonLoader} from './SkeletonLoader'
import {NavbarDemo} from './Navbar'
import Projects from './ProjectCards'
import {AboutUs} from './AboutUs'
import {ContactForm} from './Form'
import {LoginForm} from './loginForm'
import {AboutSkeleton} from './AboutSkeleton'
import {ReviewSection} from './ReviewSection'
import { CareerForm } from "./CareerForm";
import {Blogs} from './Blogs'
import {BlogForm} from './BlogForm'
import PostCard from './PostCard'
import {Portfolio} from './Portfolio'
import {ReviewForm} from './ReviewForm'
import PartnerCard from './PartnerCard'
export {ApplicantCard, NavbarDemo, ContactForm, LoginForm,AboutSkeleton, Footer, AboutUs, HeroSection, OfficePic ,GlobeDemo, HeroTitle, HoverCard, ReviewSection, ServicesCard, SkeletonLoader, Projects,CareerForm, Blogs, BlogForm, PostCard, Portfolio,ReviewForm, PartnerCard}