import React from 'react'

 const PostCard = ({imageUrl,post,fullName}) => {
  return (
    <div className="max-w-xs w-full mx-auto">
      <div
        className="cursor-pointer relative h-96 rounded-md shadow-lg overflow-hidden bg-cover bg-center flex flex-col justify-end p-4"
        style={{
          backgroundImage: `url("${imageUrl}")`,
        }}
      >
        <div className="absolute inset-0 "></div>
       
        <div className="relative z-10">
          <h1 className="text-xl font-bold text-gray-50">{post}</h1>
          <p className="text-sm text-gray-200 mt-2">
            {fullName}
          </p>
        </div>
      </div>
    </div>
  )
}
export default PostCard