// "use client"
// import React from 'react';
// import CustomBtn from './HoverBtn'
// // import BackgroundVideo from 'next-video/background-video';

// export const HeroSection = () => {
//   const BlurVideoImage = 'https://res.cloudinary.com/dcwai4hwc/image/upload/v1729575804/blurheroImg_hocfzy.webp'

//   const awesomeVideo = 'https://res.cloudinary.com/dcwai4hwc/video/upload/v1729580491/get-started_iyzpzb.mp4'
//   return (
//     <div className="w-full h-[100vh] overflow-hidden relative">
//   <video 
//     src={awesomeVideo}
//     autoPlay
//     loop
//     muted 
//     className="absolute top-0 left-0 w-full h-full object-cover z-0"
//   />
//   <div className="absolute top-0 left-0 w-full h-full bg-black opacity-0 z-5"></div> {/* Overlay for readability */}
//   <div className="flex flex-col justify-center items-center h-full relative z-10 pt-20">
//     <span className="text-[#F3F4F6] text-4xl md:text-5xl text-center font-bold">Leading Software Solutions for Your Business</span>
//     <span className="text-[#F3F4F6] mt-4 text-xl font-semibold sm:font-bold md:text-2xl text-center capitalize">
//       We provide innovative software solutions tailored to your business needs, enhancing efficiency and productivity.
//     </span>
//     <div className=" md:text-4xl text-neutral-200 py-4 flex justify-center gap-8 relative z-10">
//     <span className="text-center text-sm md:text-md font-semibold text-white capitalize">
//       <span className="md:border-t-2 border-[#000b43] block sm:inline">We Help</span> Global Brands Succeed
//     </span>
//     <span className="text-center text-sm md:text-md font-semibold text-white capitalize">
//       <span className="md:border-t-2 border-[#000b43] block sm:inline">These Values</span> Are Essential to Our Mission
//     </span>
//   </div>
//   <CustomBtn />

//   </div>
 
// </div>

//   );
// };


import React from 'react';
import CustomBtn from './HoverBtn'
import BackgroundVideo from 'next-video/background-video';

 const HeroSection = () => {
  const BlurVideoImage = 'https://res.cloudinary.com/dcwai4hwc/image/upload/v1729575804/blurheroImg_hocfzy.webp'
  const awesomeVideo = 'https://res.cloudinary.com/dcwai4hwc/video/upload/v1729580491/get-started_iyzpzb.mp4'
  return (
    <div className="video-background-container relative w-full h-[100vh] overflow-hidden">
      <BackgroundVideo src={awesomeVideo}
        poster={BlurVideoImage}
        className=" w-full h-[100vh]"
        >
        <div className="flex flex-col justify-center items-center pt-24">
          <span className="text-[#F3F4F6] text-4xl md:text-7xl text-center font-bold ">Leading Software Solutions for Your Business</span>
          <span className="text-[#F3F4F6] mt-6 text-xl font-semibold sm:font-bold md:text-2xl text-center capitalize">We provide innovative software solutions tailored to your business needs,enhancing efficiently and productivity</span>
        </div>
        <div
            className=" text-base md:text-4xl dark:text-neutral-200 py-4 flex justify-center   gap-8">
            <span className="text-center text-sm md:text-md font-semibold text-white capitalize">
              <span className="md:border-t-2 border-[000b43] block sm:inline">We Help</span> Global Brands Succeed
            </span>
            <span className="text-center  text-sm md:text-md font-semibold text-white capitalize">
              <span className="md:border-t-2 border-[000b43] block sm:inline">These Values</span> Are Essential to Our Mission
            </span>
          </div> 
             <CustomBtn />
      </BackgroundVideo>
     
    </div>
  );
};
export default HeroSection