"use client"
import { LoginForm } from '@/components'
import React from 'react'
import { motion } from 'framer-motion';

const showLogin = () => {
  return (
    <motion.div
        className="relative z-10 max-w-lg px-4 md:max-w-2xl w-full  bg-gray pt-10 bg-black text-white  bg-opacity-90 rounded-lg shadow-lg shadow-white "
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, delay: 0.1 }}
      >

       <LoginForm/>
      </motion.div>
  )
}

export default showLogin