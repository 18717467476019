import { cn } from '@/lib/utils'
import Image from 'next/image'
import React from 'react'

 const PartnerCard = ({imageUrl,message}) => {
   return (
    <div className="max-w-44 w-full h-full  sm:w-44">
      <div
         className={cn(
           " cursor-pointer overflow-hidden border border-b-0 flex-shrink-0 border-slate-700 relative card h-fit hover:scale-95 rounded-md shadow-xl   mx-auto  flex flex-col  gap-2 ",
      
        )}
      >
        <div className="">
        <Image
              alt={"Best Software Solutions | Nubitsoft "}
              className={cn("h-28  bg-white  w-60 sm:w-full object-fit ")}
              width="100"
              height="100"
              // priority
              src={imageUrl}
              />
        </div>
        <div className="text content">
          
          <p className="font-normal text-xl w-full text-center text-gray-50 relative z-10 ">
            {message}
          </p>
        </div>
      </div>
    </div>
  )
}
export default PartnerCard
